<template>
  <div>
    <Header :headerIndex="11" @showSearch="showSearch1"></Header>
    <div class="container">
      <!-- <img class="g_top_banner" src="../assets/static/icon/icon_top_banner6.png" alt="" /> -->
      <iframe v-show="isShowSearch" class="iframe" src="https://s.hcut.cn/search?___lang=en-us" frameborder="0"></iframe>
      <a :href="url">
        <!-- <p>{{url}}</p> -->
      <div v-show="!isShowSearch" class="max_1440 distributors_wrap" style="position: relative;">
        <!-- <div style="color:white;" v-html="precut.content"></div> -->
        <img @click="download" style="display: block; width: 100%" src="../assets/static/images/img7.jpg" />
        <div class="down_t1">Download Software</div>
        <div class="down_t2">
          <div>For technical support and application services,</div> 
          <div>please contact us at: cut@revolutionwrapfilm.com</div>
        </div>
      </div>
      </a>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import axios from "axios";
import api from "../api";
export default {
  components: {},
  data() {
    return {
      precut: [],
      url:'',   //链接地址
      isShowSearch:false
    };
  },
  created() {
    this.download();
  },
  methods: {
    getprecut() {
      api.doPost("/api/index/getprecut", {}, (res) => {
        // console.log(res)
        if (res.code == 200) {
          this.precut = res.data;
        }
      });
    },
    showSearch1(){
      this.isShowSearch = !this.isShowSearch
    },
    download(){
      // console.log('111')
      api.doPost("/api/index/pre_url", {}, (res) => {
        // console.log(res)
        if (res.code == 200) {
          // this.precut = res.data;
          this.url = res.data
        }
      });
      
    }
  },
};
</script>

<style scoped>
.iframe{
  width: 100%;
  height: calc(100vh - 80px);
}

.distributors_wrap {
  position: relative;
  padding: 60px 0;
}
.distributors_wrap .down_t1{
  position: absolute;
  top: 940px;
  color: #FFF;
  left: 156px;
  display: block;
  font-size: 18px;
  font-weight: 700;
  padding: 5px 10px 6px;
  background: #cf1f1a;
  line-height: 24px;
  border-radius: 4px;
}
.distributors_wrap .down_t2{
  position: absolute;
  top: 1000px;
  color: #FFF;
  left: 156px;
  font-size: 16px;
  line-height: 20px;
}

@media screen and (max-width: 1440px) {
  .distributors_wrap {
    padding: 45px 0;
  }
  .distributors_wrap .down_t1{
    top: 705px;
    left: 120px;
  }
  .distributors_wrap .down_t2{
    top: 755px;
    left: 120px;
  }
}

@media screen and (max-width: 1024px) {

.distributors_wrap .down_t1{
  top: 655px;
        left: 110px;
        font-size: 16px;
        padding: 5px 9px 6px;
        line-height: 18px;

}
.distributors_wrap .down_t2{
  top: 698px;
        left: 110px;
        font-size: 14px;
        line-height: 16px;
}
}


@media screen and (max-width: 768px) {

  .distributors_wrap .down_t1{
    top: 480px;
    left: 80px;
    font-size: 14px;
    padding: 4px 8px 5px;
    line-height: 18px;

  }
  .distributors_wrap .down_t2{
    top: 518px;
    left: 80px;
    font-size: 12px;
    line-height: 14px;
  }
}

@media screen and (max-width: 451px) {

.distributors_wrap .down_t1{
  top: 317px;
  left: 48px;
  font-size: 10px;
  padding: 2px 5px 3px;
  line-height: 14px;
}
.distributors_wrap .down_t2{
  top: 339px;
  left: 48px;
  font-size: 10px;
  line-height: 12px;
}
}

@media screen and (max-width: 431px) {

.distributors_wrap .down_t1{
  top: 302px;
  left: 46px;
  font-size: 10px;
  padding: 2px 5px 3px;
  line-height: 14px;
}
.distributors_wrap .down_t2{
  top: 324px;
  left: 48px;
  font-size: 10px;
  line-height: 12px;
}
}


@media screen and (max-width: 426px) {

  .distributors_wrap .down_t1{
    top: 302px;
    left: 46px;
    font-size: 10px;
    padding: 2px 5px 3px;
    line-height: 14px;
  }
  .distributors_wrap .down_t2{
    top: 324px;
    left: 48px;
    font-size: 10px;
    line-height: 12px;
  }
}

@media screen and (max-width: 415px) {

.distributors_wrap .down_t1{
  top: 295px;
  left: 46px;
  font-size: 10px;
  padding: 2px 5px 3px;
  line-height: 14px;
}
.distributors_wrap .down_t2{
  top: 316px;
  left: 48px;
  font-size: 10px;
  line-height: 12px;
}
}


@media screen and (max-width: 396px) {

.distributors_wrap .down_t1{
  top: 283px;
        left: 45px;
        font-size: 10px;
        padding: 1px 5px 2px;
        line-height: 14px;
}
.distributors_wrap .down_t2{
  top: 302px;
  left: 45px;
  font-size: 10px;
  line-height: 12px;
}
}

@media screen and (max-width: 376px) {

.distributors_wrap .down_t1{
  top: 271px;
        left: 40px;
        font-size: 10px;
        padding: 1px 3px;
        line-height: 14px;
}
.distributors_wrap .down_t2{
  top: 290px;
        left: 40px;
        font-size: 10px;
        line-height: 12px;
}
}
</style>
