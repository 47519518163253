<template>
  <div>
    <Header :headerIndex="11"></Header>
    <div class="container">
      <iframe class="iframe" src="https://s.hcut.cn/search?___lang=en-us" frameborder="0"></iframe>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {

    };
  },
  created() {

  },
  methods: {

  },
};
</script>

<style scoped>
.iframe{
  width: 100%;
  height: calc(100vh - 80px);
}

.distributors_wrap {
  padding: 60px 0;
}

@media screen and (max-width: 1440px) {
  .distributors_wrap {
    padding: 45px 0;
  }
}

@media screen and (max-width: 768px) {
  .distributors_wrap {
    padding: 24px 0;
  }
}
</style>
